import React from 'react';
import '../App.css';
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';
import logobsci from '../images/logobsci.png';
import ConversorMetronic from './coversorMetronic';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {name: "Guiadev", hola: "hola"};
    }
    _alertClicked = (e) =>{
        e.preventDefault();
        this.setState({
            hola: 'adios'
        })
        alert('pruebas')
    }
    render() {
        return (
            <div className="App">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-depth-64" id="topNavbar">
                            <img src={logobsci} id="logobsci" alt="Logo" />
                        </div>
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <ConversorMetronic />
                </div>
            </div>
        );
    }
}

export default Index;