import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import About from './pages/about'
import Index from './pages/index'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
            <Switch>
              <Redirect
                from="/home"
                to="/"
              />
              <Route
                path="/"
                exact
                component={Index}
              />
              <Route
                path="/about"
                exact
                component={About}
              />
              <Redirect to="/" />
            </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
