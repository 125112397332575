import React from 'react';
import '../App.css';
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

class ConversorMetronic extends React.Component {

    constructor(props) {
        super(props);
        this.state = {name: "Guiadev", hola: "hola"};
    }
    _alertClicked = (e) =>{
        e.preventDefault();
        this.setState({
            hola: 'adios'
        })
        alert('pruebas')
    }
    render() {
        return (
                <div class="ms-Grid-row" id="contentCenter">
                    {/*
                    <div class="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    <div class="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                        <div class="ms-Grid-row">
                            <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <TextField // prettier-ignore
                                    label="Voltaje"
                                    suffix="V"
                                    ariaLabel="Example text field with https:// prefix"
                                />
                            </div>
                            <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <TextField // prettier-ignore
                                    label="Voltaje"
                                    suffix="V"
                                    ariaLabel="Example text field with https:// prefix"
                                />
                            </div>
                        </div>
                        <div class="ms-Grid-row">
                            <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <TextField // prettier-ignore
                                    label="Voltaje"
                                    suffix="V"
                                    ariaLabel="Example text field with https:// prefix"
                                />
                            </div>
                            <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <TextField // prettier-ignore
                                    label="Voltaje"
                                    suffix="V"
                                    ariaLabel="Example text field with https:// prefix"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    */}
                    <div class="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    <div class="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                    <Pivot aria-label="Basic Pivot Example">
                        <PivotItem
                        headerText="Left (STN)"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'Left',
                            }}
                        >

                            <div class="ms-Grid-row">
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="Amplitude"
                                        suffix="V"
                                        ariaLabel=""
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="Rate"
                                        suffix="Hz"
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                            </div>
                            <div class="ms-Grid-row">
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="Pulse"
                                        suffix="μs"
                                        ariaLabel="Example text field with https:// prefix"
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="SoftStart/Stop"
                                        suffix="s"
                                        ariaLabel="Example text field with https:// prefix"
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                            </div>

                        </PivotItem>
                        <PivotItem headerText="Right (STN)">
                            <div class="ms-Grid-row">
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="Amplitude"
                                        suffix="V"
                                        ariaLabel=""
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="Rate"
                                        suffix="Hz"
                                        ariaLabel=""
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                            </div>
                            <div class="ms-Grid-row">
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="Pulse"
                                        suffix="μs"
                                        ariaLabel="Example text field with https:// prefix"
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                                <div class="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <TextField // prettier-ignore
                                        label="SoftStart/Stop"
                                        suffix="s"
                                        ariaLabel="Example text field with https:// prefix"
                                        style={{backgroundColor: "#e8e9d8"}}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                    </Pivot>
                    </div>
                    <div class="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    
                </div>
        );
    }
}

export default ConversorMetronic;